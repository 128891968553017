import React, { Suspense } from 'react';
import {Switch, Route} from "react-router-dom";
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom';
// import SignUp from './core/Signup';
const Signin = React.lazy(() => import('./core/Signin'))
const ResetPassword =  React.lazy(() => import('./core/ResetPassword'));
const AdminUserRoutes =  React.lazy(() => import('./auth/AdminUserRoutes'));
const MemberRoute =  React.lazy(() => import('./auth/MemberRoute'));
const Gym =  React.lazy(() => import('./gym/ManageGym'));
const GymMob =  React.lazy(() => import('./gym/mobile/ManageGym'));
const GymDashboard =  React.lazy(() => import('./gym/Dashboard'));
const Branch =  React.lazy(() => import('./branch/ManageBranch'));
const BranchMob =  React.lazy(() => import('./branch/mobile/ManageBranch'));
const ManageContent =  React.lazy(() => import('./components/content/ManageContent'));
const ManageContentMob =  React.lazy(() => import('./components/content/mobile/ManageContent'));
const ContentView =  React.lazy(() => import('./components/content/ContentViewer'));
// import ExerciseType from './components/content/ManageExerciseType';
// import ExerciseLevel from './components/content/ManageExerciseLevel';
// import TargetMuscle from './components/content/ManageTargetMuscle';
// import TestDashboard from './components/TestDashboard';
const BranchAdmin =  React.lazy(() => import('./components/admin/ManageBranchInstructor'));
const Member =  React.lazy(() => import('./member/manageMember'));
const MemberMob =  React.lazy(() => import('./member/mobile/manageMember'));
// import MemberTestDashboard from './member/memberTestDashboard';
const ManagePlanner =  React.lazy(() => import('./components/planner/ManagePlanner'));
const PlannerDashboard =  React.lazy(() => import('./components/planner/PlannerDashboard'));
// import WorkoutReportDashboard from './member/workoutReportDashboard';
// import PlannerHistoryDashboard from './member/plannerHistoryDashboard';
const GymProfile =  React.lazy(() => import('./gym/GymProfile'));
const BranchProfile =  React.lazy(() => import('./branch/BranchProfile'));
const Appointment =  React.lazy(() => import('./components/Appointment/MemberManageAppointment'));
const AppointmentMob =  React.lazy(() => import('./components/Appointment/MemberManageAppointmentMob'));
const ManageAppointment =  React.lazy(() => import('./components/Appointment/ManageAppointment'));
const MemberAppointmentView =  React.lazy(() => import('./member/memberAppointment'));

//new components
const Parameters =  React.lazy(() => import('./components/Parameters/Parameters'));
const ParametersMob =  React.lazy(() => import('./components/Parameters/ParameterMob'));
const MemberProfile =  React.lazy(() => import('./member/MemberProfile'));
const memberReport =  React.lazy(() => import('./member/memberReport'));
const MemberDashboardMob =  React.lazy(() => import('./member/mobile/memberDashboard'));
const MemberDashboardDesktop =  React.lazy(() => import('./member/desktop/memberDashboard'));
const UserDashboardMob =  React.lazy(() => import('./user/Mobile/UserDashboard'));
const UserDashboardDesktop =  React.lazy(() => import('./user/Desktop/UserDashboard'));
const Scanner =  React.lazy(() => import('./core/Scanner'));
const MemberWorkoutSchedule =  React.lazy(() => import('./member/mobile/memberWorkoutSchedule'));
const ManageExercise =  React.lazy(() => import('./components/planner/manageExercise'));
const AddExercise =  React.lazy(() => import('./components/planner/addExercise'));
const MemberRegistration =  React.lazy(() => import("./member/mobile/memberRegistration"));
const ManageUsers =  React.lazy(() => import("./user/ManageUsers"));
const ManageUsersMob =  React.lazy(() => import('./user/ManageUsersMob'));
const UserRegistration =  React.lazy(() => import('./core/UserSignup'));
const SetPassword =  React.lazy(() => import('./core/SetPassword'));
const PhysiologicalParameter =   React.lazy(() => import('./member/desktop/physiologicalParameter'));
const FitnessMeasurement =  React.lazy(() => import('./member/desktop/fitnessMeasurement'));
const FitnessTest =  React.lazy(() => import('./member/desktop/fitnessTest'));
const WorkoutHistory =  React.lazy(() => import('./member/desktop/workoutHistory'));
const MemberHistory =  React.lazy(() => import('./member/mobile/memberHistory'));
const SmartScreensDashboardDesktop =  React.lazy(() => import('./components/smartScreen/Desktop/ManageScreens'));
const SmartScreensDashboardMobile =  React.lazy(() => import('./components/smartScreen/Mobile/ManageScreens'));
// import MemberNotificationDesktop from './member/desktop/memberNotification';
// import MemberNotificationMobile from './member/mobile/memberNotification';
// import io from "socket.io-client";
const UserNotificationMob =  React.lazy(() => import('./components/Notification/mobileNotification'));
const MemberReports =  React.lazy(() => import('./member/desktop/memberReports'));
const MobMemberReports =  React.lazy(() => import('./member/mobile/memberReports'));
// const SocketEndpoint = "http://localhost:5000";

const PrivacyPolicy =  React.lazy(() => import('./privacy-policy/privacyPolicy'));
const TermsAndConditions =  React.lazy(() => import('./terms-and-condititons/termsAndCondition'));
// import Home from './home';
const ManageEnquiryDesktop =  React.lazy(() => import('./components/enquiry/desktop/manageEnquiry'));
const ManageEnquiryMobile =  React.lazy(() => import('./components/enquiry/mobile/manageEnquiry'));

// Enquiry Form
const Enquiry =  React.lazy(() => import('./components/enquiry'));



                                           

const Routes = () => {


    return (
        <Suspense fallback={<div>Loading...</div>}>
            <BrowserRouter>
                <Switch>
                    {/* <Route path="/" exact component={Home} /> */}
                    <Route path="/scanner" exact component={Scanner}/>
                    <Route path={["/signin","/"]} exact component={Signin}/>
                    {/* <Route path="/signup" exact component={SignUp}/> */}
                    <Route path="/resetpassword/:token" exact component={ResetPassword} />
                    <Route path="/setpassword/:token" exact component={SetPassword}/>
                    <Route path="/user/registration" exact component={UserRegistration} />
                    <AdminUserRoutes path="/user/Dashboard" exact component={window.innerWidth < 549 ? UserDashboardMob : UserDashboardDesktop}/> 
                    <AdminUserRoutes path="/admin/gym" exact component={window.innerWidth < 549 ? GymMob : Gym}/>
                    <AdminUserRoutes path="/gym/profile/:gymId" exact component={GymProfile} />
                    <AdminUserRoutes path="/branch/profile/:branchId" exact component={BranchProfile}/>
                    <AdminUserRoutes path="/admin/branch" exact component={window.innerWidth < 549 ? BranchMob : Branch}/>
                    <AdminUserRoutes path="/contents/:gymId?/:branchId?/:memberId?" exact component={window.innerWidth < 549 ? ManageContentMob : ManageContent}/>
                    <AdminUserRoutes path="/content/:contentId" exact component={ContentView}/>
                    {/* <AdminUserRoutes path="/:itemId/exercise-type" exact component={ExerciseType}/>
                    <AdminUserRoutes path="/:itemId/exercise-level" exact component={ExerciseLevel}/>
                    <AdminUserRoutes path="/:itemId/target-muscle" exact component={TargetMuscle}/>
                    <AdminUserRoutes path="/:itemId/test-list" exact  component={TestDashboard}/> */}
                    <AdminUserRoutes path="/members" exact component={window.innerWidth < 549 ? MemberMob : Member}/>
                    <AdminUserRoutes path="/member/details/:memberId" exact component={window.innerWidth < 549 ? MobMemberReports : MemberReports}/>
                    {/* <AdminUserRoutes path="/member/tests/:gymId/:branchId/:memberId" exact component={MemberTestDashboard}/> */}
                    <AdminUserRoutes path="/planners" exact component={PlannerDashboard}/>
                    <AdminUserRoutes path="/planner/:plannerId" exact component={ManagePlanner}/>
                    {/* <AdminUserRoutes path="/workout/reports/:branchId/:memberId" exact component={WorkoutReportDashboard}/> */}
                    <AdminUserRoutes path="/branch/admin/users" exact component={BranchAdmin}/>
                    <AdminUserRoutes path="/gym/dashboard" exact component={GymDashboard}/>
                    {/* <AdminUserRoutes path="/planner/historys/:branchId/:memberId" exact component={PlannerHistoryDashboard}/>             */}
                    <AdminUserRoutes path="/manage/appointment/:gymId?/:branchId?/:memberId?" exact component={window.innerWidth < 549 ? AppointmentMob : Appointment}/>
                    <AdminUserRoutes path="/member/appointment/:memberId" exact component={MemberAppointmentView}/>
                    <AdminUserRoutes path="/appointment" exact component={ManageAppointment}/>
                    {/* new routes */}
                    <AdminUserRoutes path="/member/histoxry" exact component={MemberHistory} />
                    <AdminUserRoutes path="/member/profile/:memberId" exact component={MemberProfile} />
                    <AdminUserRoutes path="/member-report" exact component={memberReport} />
                    <AdminUserRoutes path="/parameters/:userId" exact component={Parameters} />
                    <AdminUserRoutes path="/parameters" exact component={window.innerWidth < 549 ? ParametersMob : Parameters} />
                    <AdminUserRoutes path="/users" exact component={window.innerWidth < 549 ? ManageUsersMob : ManageUsers} />
                    <AdminUserRoutes path="/members/physiological_parameter_and_body_composition/:memberID" exact component={PhysiologicalParameter}/>
                    <AdminUserRoutes path="/members/fitness_measurement/:memberID" exact component={window.innerWidth > 549 && FitnessMeasurement} />
                    <AdminUserRoutes path="/members/fitness_test/:memberID" exact component={window.innerWidth > 549 && FitnessTest} />
                    <AdminUserRoutes path="/members/workout_history/:memberID" exact component={window.innerWidth > 549 && WorkoutHistory} />
                    <AdminUserRoutes path="/members/history/:memberID" exact component={MemberHistory} />
                    <MemberRoute path="/member/Dashboard" exact component={window.innerWidth < 549 ? MemberDashboardMob : MemberDashboardDesktop} /> 
                    <MemberRoute path="/member/workout-chart" exact component={window.innerWidth < 549 ? MemberDashboardMob : MemberDashboardDesktop} />
                    <MemberRoute path="/member/reports" exact component={window.innerWidth < 549 ? MemberDashboardMob : MemberDashboardDesktop} />
                    <MemberRoute path="/member/appointment" exact component={window.innerWidth < 549 ? MemberDashboardMob : MemberDashboardDesktop} />
                    <MemberRoute path="/member/notification" exact component={window.innerWidth < 549 ? MemberDashboardMob : MemberDashboardDesktop} />
                    <MemberRoute path="/member/workout-history" exact component={window.innerWidth < 549 ? MemberDashboardMob : MemberDashboardDesktop} />
                    <MemberRoute path="/member/profile" exact component={MemberDashboardMob} />
                    <MemberRoute path="/member/schedule/workout" exact component={MemberWorkoutSchedule} />
                    <MemberRoute path="/member/planner/exercise" exact component={ManageExercise} />
                    <MemberRoute path="/member/add/exercise" exact component={AddExercise} />
                    <Route path={["/member/registration","/member/account-setup/:memberId/:token"]} exact component={MemberRegistration} />
                    <AdminUserRoutes path="/screens" exact component={window.innerWidth > 549 ? SmartScreensDashboardDesktop : SmartScreensDashboardMobile}/>
                    <Route path="/notifications" exact component={window.innerWidth > 549 ? null : UserNotificationMob}/>
                    <Route path="/privacy-policy" exact component={PrivacyPolicy}/>
                    <Route path="/terms-condition" exact component={TermsAndConditions}/>
                    <Route path="/enquiry/:branchId" exact component={Enquiry}/>
                    <Route path="/enquiries" exact component={window.innerWidth < 549 ? ManageEnquiryMobile : ManageEnquiryDesktop} />
                </Switch>
            </BrowserRouter>
        </Suspense>
    )
};

export default Routes;